import * as React from "react";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import { FadeInSection } from "../components/FadeInSection";

const TagRoute = (props) =>  {

    const posts = props.data.allMarkdownRemark.edges;

    const postLinks = posts.map((post) => (
      <div className="column is-4" key={post.node.fields.slug}>
        <Link to={post.node.fields.slug}>
          {/* <h2 className="is-size-2">{post.node.frontmatter.title}</h2> */}
          <form className="is-flex is-flex-direction-column is-align-items-center">
            <img
              style={{ 
                width: "100%",
                aspectRatio: "1 / 1",
                objectFit: "cover",
              }}
              src={ post.node.frontmatter.featuredimage ? post.node.frontmatter.featuredimage.childImageSharp.gatsbyImageData.images.fallback.src : "" }
              onMouseEnter={ (e) => e.target.src = post.node.frontmatter.hoveredimage ? post.node.frontmatter.hoveredimage.childImageSharp.gatsbyImageData.images.fallback.src : "" }               
              onMouseLeave={ (e) => e.target.src = post.node.frontmatter.featuredimage ? post.node.frontmatter.featuredimage.childImageSharp.gatsbyImageData.images.fallback.src : "" }
            ></img>
            <FadeInSection>
            <button 
              // id={"work-button-" + index}
              className="work-button"
              style={{ 
                
                fontSize: "1.5rem",
                fontWeight: "100",
                position: "relative", 
                top: "-6rem", 
                padding: "1rem",
                paddingTop: "0.7rem",
                border: "1px solid black",
                borderRadius: "50px",
                backgroundColor: "rgb(255,255,255, 0.5)",
                cursor: "pointer",
              }}
            >
              see more &rarr;
            </button>
          </FadeInSection>
          </form>
        </Link>
      </div>
    ));

    const { tag } = props.pageContext;
    const { title } = props.data.site.siteMetadata;
    const { totalCount } = props.data.allMarkdownRemark;
    const tagHeader = tag;
    // `${totalCount} post${
    //   totalCount === 1 ? "" : "s"
    // } tagged with “${tag}”`;

    return (
      <Layout>
        <section className="section">
          <Helmet title={`${title} | ${tag} `} />
          <div className="container content">
            <div className="columns">
              <div
                className="column is-10 is-offset-1"
                style={{ marginBottom: "6rem" }}
              >
                <h2 className="title is-size-2 is-bold-light">{tagHeader}</h2><br/>
                <div 
                  className="columns is-multiline"
                >
                  {postLinks}
                </div>
                <p>
                  <br />
                  <Link to="/price">&larr; Zurück zu den Preisen</Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
}

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            featuredimage {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: CONSTRAINED)
              }
            }
            hoveredimage {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`;
